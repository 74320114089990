import React, { useCallback, useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import "pure-react-carousel/dist/react-carousel.es.css"
import SEO from "../components/seo"
import GridVoitures from "../components/GridVoitures"
import ActualiteGrid from "../components/ActualiteGrid"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

const Actualite = props => {
  const { data, pageContext } = props

  const id = pageContext.id
  const actualite = data.nodeActualites
  const { edges: modelsHome } = data.allTaxonomyTermModels
  const { edges: actualites } = data.allNodeActualites
  const { marquename } = pageContext

  function createMarkup(field) {
    return { __html: field }
  }

  const urlVideo = "https://www.youtube.com/embed"
  const photos = []
  actualite.relationships.field_galleries_actualites.map(photo =>
    photos.push({
      src: photo.localFile.childImageSharp.fluid.srcWebp,
      title: pageContext.title,
      alt: pageContext.title,
      width: 3,
      srcSet: [
        `${photo.localFile.childImageSharp.fluid.srcSetWebp} 1024w`,
        `${photo.localFile.childImageSharp.fluid.srcSetWebp} 640w`,
      ],
      sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
      height: 2,
    })
  )
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const style = {
    marginTop: "15px",
    marginBottom: "20px",
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }
  return (
    <Layout>
      <SEO
        title={pageContext.title}
        description={pageContext.field_description}
        image={
          actualite.relationships.field_image.localFile.childImageSharp.original
        }
      />
      <div className="section section-md bg-dark text-white "></div>

      <section className="section section-sm py-6">
        <div className="container">
          <div className="row align-items-center justify-content-center mt-n7">
            <div className="col-md-12 col-lg-12 mb-5">
              <div className="card shadow-sm border-light p-2 p-md-3 p-lg-5">
                <div className="card-header">
                  <div className="mb-5">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                        <li className="breadcrumb-item">
                          <Link to={`/`}>Accueil</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to={`/actualites`}>Actualités</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {pageContext.title}
                        </li>
                      </ol>
                    </nav>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-12 text-center mb-1">
                      <h1 className="display-4 mb-3">{pageContext.title}</h1>
                      Date publication :{" "}
                      <time dateTime={actualite.created}>
                        {actualite.created}
                      </time>
                      <div class="card bg-white border border-light p-4 mb-4">
                        <div class="d-flex justify-content-between align-items-center mb-3"></div>
                        <h3 className="lead">{actualite.field_description}</h3>
                      </div>
                      {actualite.field_video_id ? (
                        <div className="embed-responsive embed-responsive-16by9">
                          <iframe
                            src={`${urlVideo}/${actualite.field_video_id}`}
                            allowFullScreen=""
                            width="100%"
                            height="100%"
                            frameBorder="0"
                          ></iframe>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="mb-3">
                    <p
                      className="lead"
                      dangerouslySetInnerHTML={createMarkup(
                        actualite.body.value
                      )}
                    />
                  </div>

                  {photos ? (
                    <div>
                      <div>
                        <Gallery photos={photos} onClick={openLightbox} />
                        <ModalGateway>
                          {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                              <Carousel
                                currentIndex={currentImage}
                                views={photos.map(x => ({
                                  ...x,
                                  srcset: x.srcSet,
                                  caption: x.title,
                                }))}
                              />
                            </Modal>
                          ) : null}
                        </ModalGateway>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-3">
                  <span className="h6">Actualité en relation</span>
                </div>
                <div className="wi-tags">
                  {actualite.relationships.field_tags.map(tag => (
                    <a
                      title={tag.name}
                      key={tag.name}
                      href={`/categorie${tag.path.alias}/`}
                    >
                      {tag.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section pb-0 mb-0 section bg-dark  overflow-hidden z-2">
        <div className="container z-2">
          <h3 className="text-white h2">Voitures neuves à découvrir</h3>
          <GridVoitures voitures={modelsHome} colmd={3} />
        </div>
      </section>

      <section className="section pb-0 mb-0 section-md overflow-hidden pt-2 bg-soft">
        <div className="container">
          <h3 className="h2">Actualité</h3>

          <ActualiteGrid
            title="Actualité"
            withHeader={false}
            actualites={actualites}
          />
        </div>
      </section>
    </Layout>
  )
}

export default Actualite

export const pageQuery = graphql`
  query Actualite($id: String!, $marquename: String!) {
    allNodeActualites(
      limit: 3
      sort: { fields: created, order: DESC }
      filter: {
        relationships: { field_marques: { name: { eq: $marquename } } }
      }
    ) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          field_description
          field_video_id
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      }
    }

    allTaxonomyTermModels(
      filter: {
        relationships: { field_marques: { name: { eq: $marquename } } }
      }
      limit: 4
    ) {
      edges {
        node {
          path {
            alias
          }
          field_isnew
          field_ispromo
          name
          id
          relationships {
            field_marques {
              path {
                alias
              }
            }
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
          field_prix_min
          field_prix_max
          field_chevaux_fiscale
          field_carburant
          field_chevaux
          field_isnew
          field_ispromo
        }
      }
    }

    nodeActualites(id: { eq: $id }) {
      title
      created(formatString: "MMMM DD, YYYY")
      field_description
      field_video_id
      path {
        alias
      }
      body {
        value
      }
      relationships {
        field_tags {
          name
          path {
            alias
          }
        }
        field_galleries_actualites {
          localFile {
            childImageSharp {
              resize(width: 640, jpegProgressive: true) {
                src
                tracedSVG
                width
                height
                aspectRatio
                originalName
              }
              fluid {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
              original {
                src
              }
            }
          }
        }
        field_image {
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
              fluid {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    }
  }
`
